<template>
  <div class="console-onboard-wrapper">
    <div class="console-onboard"
    :class="[($route.name !== 'signUp') && ($route.name !== 'login') ? '' : 'form-style']">
      <header class="header" v-if="($route.name !== 'signUp') && ($route.name !== 'login')">
        <a href="https://www.cricketapi.com" class="rz-logo">
          <img class="logo" src="@/assets/rca-logo-black.svg" alt="Cricket API Logo" />
        </a>
        <div
          class="user-info"
          v-if="($route.name !== 'signUp') && ($route.name !== 'login')"
        >
        <template v-if="activeUser && activeUser.name">
          <svg class="profile-icon">
            <use v-bind="{'xlink:href':'#profile-icon'}" />
          </svg>
          <span class="user-name">{{ activeUser.name }}</span>
          <UserDropdown />
        </template>
        </div>
      </header>
      <div class="page-container"
      :class="[($route.name !== 'signUp') && ($route.name !== 'login') ? '' : 'form-style']"
      >
        <router-view class="flex-col" />
      </div>
      <footer class="footer" v-if="($route.name !== 'signUp') && ($route.name !== 'login')">
        <div class="flex-col-left">
          <p>© Roanuz Softwares Private Limited</p>
        </div>
        <div class="flex-col-right"
          v-if="tocLinks.linkslist">
          <template v-for="(linkItem, index) in tocLinks.linkslist.links">
            <rz-link-item :key="index" :link="linkItem" />
          </template>
        </div>
      </footer>
    </div>
    <AllSvg />
  </div>
</template>

<style lang="scss">
</style>

<style lang="scss">
.console-onboard {
  --onboard-padding: #{size(22)};

  @media screen and (min-width: $breakpoint-lg) {
    --onboard-padding: #{size(52)};
  }
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  display: block;
  padding-right: var(--onboard-padding);
  padding-left: var(--onboard-padding);
  max-width: var(--content-max-width);
  margin: auto;
  &-wrapper {
    background-color: var(--onboard-bg-color);
  }
  .page-container {
    padding-bottom: size(65);
    &.form-style {
      padding: 0;
    }
  }
  &.form-style {
    padding: 0;
    max-width: 100%;
    width: 100%;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding-top: size(32);
    padding-bottom: size(32);
    .user-info {
      height: size(28);
      padding: size(13.5) 0;
      position: relative;
      & .user-dropdown {
        display: none;
      }
      &:hover .user-dropdown {
        display: block;
      }
      .user-name {
        display: inline-block;
        padding-left: size(13);
        font-size: size(20);
        line-height: size(24);
        color: var(--ca-title-color);
        margin-left: size(0);
        cursor: pointer;
      }
    }
  }

  .footer {
    font-size: size(14);
    line-height: size(17);
    margin: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: calc(100% - #{size(104)});
    display: block;
    padding: 0;
    margin: auto;
    @media screen and (min-width: $breakpoint-md) {
      display: flex;
      justify-content: space-between;
      padding: size(11) var(--onboard-padding) size(11) var(--onboard-padding);
    }
    .flex-col-right {
      margin-top: size(15);
      @media screen and (min-width: $breakpoint-md) {
        margin-top: 0;
      }
      .link-item {
        margin-right: size(16);
        margin-bottom: size(8);
        @media screen and (min-width: $breakpoint-md) {
          margin-left: size(16);
          margin-bottom: 0;
          margin-right: 0;
        }
        color: var(--regular-text);
        display: inline-block;
      }
    }
  }

  svg {
    &.profile-icon {
      height: size(20);
      width: size(20);
    }
  }

  .hide {
    display: none;
  }
}
</style>
<script>
import { mapState } from 'vuex';
import Config from '@/config';
import UserDropdown from '@/components/Onboarding/UserDropdown.vue';

export default {
  computed: {
    ...mapState({
      activeUser: (state) => state.boot.instance.user,
      tocLinks: (state) => state.link.cricketFooterTOC,
    }),
  },
  components: {
    UserDropdown,
  },

  data() {
    return {
      linkItemList: [
        {
          text: 'API terms of use.',
          url: `${Config.cricketHost}/legal/API-Terms-of-use/`,
        },
        {
          text: 'Terms & Privacy policy.',
          url: `${Config.cricketHost}/legal/Privacy-Policy/`,
        },
        {
          text: 'Refund policy',
          url: `${Config.cricketHost}/legal/Refund-Policy/`,
        },
      ],
    };
  },
};
</script>
