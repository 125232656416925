export class FieldHandler {
  constructor(obj = {}) {
    this.showError = obj.showError || false;
    this.errorMsg = obj.errorMsg || null;
    this.minValue = obj.minValue || 0;
    this.regExp = obj.regExp || null;
  }

  validateField(value) {
    let valid = true;
    if ((!value) || (value === '')) {
      valid = false;
    }
    if (this.regExp && !(this.regExp.test(value))) {
      valid = false;
    }
    if (this.minValue && (this.minValue > parseInt(value, 10))) {
      valid = false;
    }
    this.showError = !valid;
    return valid;
  }
}

export const countryList = [
  {
    key: 'BD',
    name: 'Bangladesh',
  },
  {
    key: 'BE',
    name: 'Belgium',
  },
  {
    key: 'BF',
    name: 'Burkina Faso',
  },
  {
    key: 'BG',
    name: 'Bulgaria',
  },
  {
    key: 'BA',
    name: 'Bosnia and Herzegovina',
  },
  {
    key: 'BB',
    name: 'Barbados',
  },
  {
    key: 'WF',
    name: 'Wallis and Futuna',
  },
  {
    key: 'BL',
    name: 'Saint Barthelemy',
  },
  {
    key: 'BM',
    name: 'Bermuda',
  },
  {
    key: 'BN',
    name: 'Brunei',
  },
  {
    key: 'BO',
    name: 'Bolivia',
  },
  {
    key: 'BH',
    name: 'Bahrain',
  },
  {
    key: 'BI',
    name: 'Burundi',
  },
  {
    key: 'BJ',
    name: 'Benin',
  },
  {
    key: 'BT',
    name: 'Bhutan',
  },
  {
    key: 'JM',
    name: 'Jamaica',
  },
  {
    key: 'BV',
    name: 'Bouvet Island',
  },
  {
    key: 'BW',
    name: 'Botswana',
  },
  {
    key: 'WS',
    name: 'Samoa',
  },
  {
    key: 'BQ',
    name: 'Bonaire, Saint Eustatius and Saba ',
  },
  {
    key: 'BR',
    name: 'Brazil',
  },
  {
    key: 'BS',
    name: 'Bahamas',
  },
  {
    key: 'JE',
    name: 'Jersey',
  },
  {
    key: 'BY',
    name: 'Belarus',
  },
  {
    key: 'BZ',
    name: 'Belize',
  },
  {
    key: 'RU',
    name: 'Russia',
  },
  {
    key: 'RW',
    name: 'Rwanda',
  },
  {
    key: 'RS',
    name: 'Serbia',
  },
  {
    key: 'TL',
    name: 'East Timor',
  },
  {
    key: 'RE',
    name: 'Reunion',
  },
  {
    key: 'TM',
    name: 'Turkmenistan',
  },
  {
    key: 'TJ',
    name: 'Tajikistan',
  },
  {
    key: 'RO',
    name: 'Romania',
  },
  {
    key: 'TK',
    name: 'Tokelau',
  },
  {
    key: 'GW',
    name: 'Guinea-Bissau',
  },
  {
    key: 'GU',
    name: 'Guam',
  },
  {
    key: 'GT',
    name: 'Guatemala',
  },
  {
    key: 'GS',
    name: 'South Georgia and the South Sandwich Islands',
  },
  {
    key: 'GR',
    name: 'Greece',
  },
  {
    key: 'GQ',
    name: 'Equatorial Guinea',
  },
  {
    key: 'GP',
    name: 'Guadeloupe',
  },
  {
    key: 'JP',
    name: 'Japan',
  },
  {
    key: 'GY',
    name: 'Guyana',
  },
  {
    key: 'GG',
    name: 'Guernsey',
  },
  {
    key: 'GF',
    name: 'French Guiana',
  },
  {
    key: 'GE',
    name: 'Georgia',
  },
  {
    key: 'GD',
    name: 'Grenada',
  },
  {
    key: 'GB',
    name: 'United Kingdom',
  },
  {
    key: 'GA',
    name: 'Gabon',
  },
  {
    key: 'SV',
    name: 'El Salvador',
  },
  {
    key: 'GN',
    name: 'Guinea',
  },
  {
    key: 'GM',
    name: 'Gambia',
  },
  {
    key: 'GL',
    name: 'Greenland',
  },
  {
    key: 'GI',
    name: 'Gibraltar',
  },
  {
    key: 'GH',
    name: 'Ghana',
  },
  {
    key: 'OM',
    name: 'Oman',
  },
  {
    key: 'TN',
    name: 'Tunisia',
  },
  {
    key: 'JO',
    name: 'Jordan',
  },
  {
    key: 'HR',
    name: 'Croatia',
  },
  {
    key: 'HT',
    name: 'Haiti',
  },
  {
    key: 'HU',
    name: 'Hungary',
  },
  {
    key: 'HK',
    name: 'Hong Kong',
  },
  {
    key: 'HN',
    name: 'Honduras',
  },
  {
    key: 'HM',
    name: 'Heard Island and McDonald Islands',
  },
  {
    key: 'VE',
    name: 'Venezuela',
  },
  {
    key: 'PR',
    name: 'Puerto Rico',
  },
  {
    key: 'PS',
    name: 'Palestinian Territory',
  },
  {
    key: 'PW',
    name: 'Palau',
  },
  {
    key: 'PT',
    name: 'Portugal',
  },
  {
    key: 'SJ',
    name: 'Svalbard and Jan Mayen',
  },
  {
    key: 'PY',
    name: 'Paraguay',
  },
  {
    key: 'IQ',
    name: 'Iraq',
  },
  {
    key: 'PA',
    name: 'Panama',
  },
  {
    key: 'PF',
    name: 'French Polynesia',
  },
  {
    key: 'PG',
    name: 'Papua New Guinea',
  },
  {
    key: 'PE',
    name: 'Peru',
  },
  {
    key: 'PK',
    name: 'Pakistan',
  },
  {
    key: 'PH',
    name: 'Philippines',
  },
  {
    key: 'PN',
    name: 'Pitcairn',
  },
  {
    key: 'PL',
    name: 'Poland',
  },
  {
    key: 'PM',
    name: 'Saint Pierre and Miquelon',
  },
  {
    key: 'ZM',
    name: 'Zambia',
  },
  {
    key: 'EH',
    name: 'Western Sahara',
  },
  {
    key: 'EE',
    name: 'Estonia',
  },
  {
    key: 'EG',
    name: 'Egypt',
  },
  {
    key: 'ZA',
    name: 'South Africa',
  },
  {
    key: 'EC',
    name: 'Ecuador',
  },
  {
    key: 'IT',
    name: 'Italy',
  },
  {
    key: 'VN',
    name: 'Vietnam',
  },
  {
    key: 'SB',
    name: 'Solomon Islands',
  },
  {
    key: 'ET',
    name: 'Ethiopia',
  },
  {
    key: 'SO',
    name: 'Somalia',
  },
  {
    key: 'ZW',
    name: 'Zimbabwe',
  },
  {
    key: 'SA',
    name: 'Saudi Arabia',
  },
  {
    key: 'ES',
    name: 'Spain',
  },
  {
    key: 'ER',
    name: 'Eritrea',
  },
  {
    key: 'ME',
    name: 'Montenegro',
  },
  {
    key: 'MD',
    name: 'Moldova',
  },
  {
    key: 'MG',
    name: 'Madagascar',
  },
  {
    key: 'MF',
    name: 'Saint Martin',
  },
  {
    key: 'MA',
    name: 'Morocco',
  },
  {
    key: 'MC',
    name: 'Monaco',
  },
  {
    key: 'UZ',
    name: 'Uzbekistan',
  },
  {
    key: 'MM',
    name: 'Myanmar',
  },
  {
    key: 'ML',
    name: 'Mali',
  },
  {
    key: 'MO',
    name: 'Macao',
  },
  {
    key: 'MN',
    name: 'Mongolia',
  },
  {
    key: 'MH',
    name: 'Marshall Islands',
  },
  {
    key: 'MK',
    name: 'Macedonia',
  },
  {
    key: 'MU',
    name: 'Mauritius',
  },
  {
    key: 'MT',
    name: 'Malta',
  },
  {
    key: 'MW',
    name: 'Malawi',
  },
  {
    key: 'MV',
    name: 'Maldives',
  },
  {
    key: 'MQ',
    name: 'Martinique',
  },
  {
    key: 'MP',
    name: 'Northern Mariana Islands',
  },
  {
    key: 'MS',
    name: 'Montserrat',
  },
  {
    key: 'MR',
    name: 'Mauritania',
  },
  {
    key: 'IM',
    name: 'Isle of Man',
  },
  {
    key: 'UG',
    name: 'Uganda',
  },
  {
    key: 'TZ',
    name: 'Tanzania',
  },
  {
    key: 'MY',
    name: 'Malaysia',
  },
  {
    key: 'MX',
    name: 'Mexico',
  },
  {
    key: 'IL',
    name: 'Israel',
  },
  {
    key: 'FR',
    name: 'France',
  },
  {
    key: 'IO',
    name: 'British Indian Ocean Territory',
  },
  {
    key: 'SH',
    name: 'Saint Helena',
  },
  {
    key: 'FI',
    name: 'Finland',
  },
  {
    key: 'FJ',
    name: 'Fiji',
  },
  {
    key: 'FK',
    name: 'Falkland Islands',
  },
  {
    key: 'FM',
    name: 'Micronesia',
  },
  {
    key: 'FO',
    name: 'Faroe Islands',
  },
  {
    key: 'NI',
    name: 'Nicaragua',
  },
  {
    key: 'NL',
    name: 'Netherlands',
  },
  {
    key: 'NO',
    name: 'Norway',
  },
  {
    key: 'NA',
    name: 'Namibia',
  },
  {
    key: 'VU',
    name: 'Vanuatu',
  },
  {
    key: 'NC',
    name: 'New Caledonia',
  },
  {
    key: 'NE',
    name: 'Niger',
  },
  {
    key: 'NF',
    name: 'Norfolk Island',
  },
  {
    key: 'NG',
    name: 'Nigeria',
  },
  {
    key: 'NZ',
    name: 'New Zealand',
  },
  {
    key: 'NP',
    name: 'Nepal',
  },
  {
    key: 'NR',
    name: 'Nauru',
  },
  {
    key: 'NU',
    name: 'Niue',
  },
  {
    key: 'CK',
    name: 'Cook Islands',
  },
  {
    key: 'XK',
    name: 'Kosovo',
  },
  {
    key: 'CI',
    name: 'Ivory Coast',
  },
  {
    key: 'CH',
    name: 'Switzerland',
  },
  {
    key: 'CO',
    name: 'Colombia',
  },
  {
    key: 'CN',
    name: 'China',
  },
  {
    key: 'CM',
    name: 'Cameroon',
  },
  {
    key: 'CL',
    name: 'Chile',
  },
  {
    key: 'CC',
    name: 'Cocos Islands',
  },
  {
    key: 'CA',
    name: 'Canada',
  },
  {
    key: 'CG',
    name: 'Republic of the Congo',
  },
  {
    key: 'CF',
    name: 'Central African Republic',
  },
  {
    key: 'CD',
    name: 'Democratic Republic of the Congo',
  },
  {
    key: 'CZ',
    name: 'Czech Republic',
  },
  {
    key: 'CY',
    name: 'Cyprus',
  },
  {
    key: 'CX',
    name: 'Christmas Island',
  },
  {
    key: 'CR',
    name: 'Costa Rica',
  },
  {
    key: 'CW',
    name: 'Curacao',
  },
  {
    key: 'CV',
    name: 'Cape Verde',
  },
  {
    key: 'CU',
    name: 'Cuba',
  },
  {
    key: 'SZ',
    name: 'Swaziland',
  },
  {
    key: 'SY',
    name: 'Syria',
  },
  {
    key: 'SX',
    name: 'Sint Maarten',
  },
  {
    key: 'KG',
    name: 'Kyrgyzstan',
  },
  {
    key: 'KE',
    name: 'Kenya',
  },
  {
    key: 'SS',
    name: 'South Sudan',
  },
  {
    key: 'SR',
    name: 'Suriname',
  },
  {
    key: 'KI',
    name: 'Kiribati',
  },
  {
    key: 'KH',
    name: 'Cambodia',
  },
  {
    key: 'KN',
    name: 'Saint Kitts and Nevis',
  },
  {
    key: 'KM',
    name: 'Comoros',
  },
  {
    key: 'ST',
    name: 'Sao Tome and Principe',
  },
  {
    key: 'SK',
    name: 'Slovakia',
  },
  {
    key: 'KR',
    name: 'South Korea',
  },
  {
    key: 'SI',
    name: 'Slovenia',
  },
  {
    key: 'KP',
    name: 'North Korea',
  },
  {
    key: 'KW',
    name: 'Kuwait',
  },
  {
    key: 'SN',
    name: 'Senegal',
  },
  {
    key: 'SM',
    name: 'San Marino',
  },
  {
    key: 'SL',
    name: 'Sierra Leone',
  },
  {
    key: 'SC',
    name: 'Seychelles',
  },
  {
    key: 'KZ',
    name: 'Kazakhstan',
  },
  {
    key: 'KY',
    name: 'Cayman Islands',
  },
  {
    key: 'SG',
    name: 'Singapore',
  },
  {
    key: 'SE',
    name: 'Sweden',
  },
  {
    key: 'SD',
    name: 'Sudan',
  },
  {
    key: 'DO',
    name: 'Dominican Republic',
  },
  {
    key: 'DM',
    name: 'Dominica',
  },
  {
    key: 'DJ',
    name: 'Djibouti',
  },
  {
    key: 'DK',
    name: 'Denmark',
  },
  {
    key: 'VG',
    name: 'British Virgin Islands',
  },
  {
    key: 'DE',
    name: 'Germany',
  },
  {
    key: 'YE',
    name: 'Yemen',
  },
  {
    key: 'DZ',
    name: 'Algeria',
  },
  {
    key: 'US',
    name: 'United States',
  },
  {
    key: 'UY',
    name: 'Uruguay',
  },
  {
    key: 'YT',
    name: 'Mayotte',
  },
  {
    key: 'UM',
    name: 'United States Minor Outlying Islands',
  },
  {
    key: 'LB',
    name: 'Lebanon',
  },
  {
    key: 'LC',
    name: 'Saint Lucia',
  },
  {
    key: 'LA',
    name: 'Laos',
  },
  {
    key: 'TV',
    name: 'Tuvalu',
  },
  {
    key: 'TW',
    name: 'Taiwan',
  },
  {
    key: 'TT',
    name: 'Trinidad and Tobago',
  },
  {
    key: 'TR',
    name: 'Turkey',
  },
  {
    key: 'LK',
    name: 'Sri Lanka',
  },
  {
    key: 'LI',
    name: 'Liechtenstein',
  },
  {
    key: 'LV',
    name: 'Latvia',
  },
  {
    key: 'TO',
    name: 'Tonga',
  },
  {
    key: 'LT',
    name: 'Lithuania',
  },
  {
    key: 'LU',
    name: 'Luxembourg',
  },
  {
    key: 'LR',
    name: 'Liberia',
  },
  {
    key: 'LS',
    name: 'Lesotho',
  },
  {
    key: 'TH',
    name: 'Thailand',
  },
  {
    key: 'TF',
    name: 'French Southern Territories',
  },
  {
    key: 'TG',
    name: 'Togo',
  },
  {
    key: 'TD',
    name: 'Chad',
  },
  {
    key: 'TC',
    name: 'Turks and Caicos Islands',
  },
  {
    key: 'LY',
    name: 'Libya',
  },
  {
    key: 'VA',
    name: 'Vatican',
  },
  {
    key: 'VC',
    name: 'Saint Vincent and the Grenadines',
  },
  {
    key: 'AE',
    name: 'United Arab Emirates',
  },
  {
    key: 'AD',
    name: 'Andorra',
  },
  {
    key: 'AG',
    name: 'Antigua and Barbuda',
  },
  {
    key: 'AF',
    name: 'Afghanistan',
  },
  {
    key: 'AI',
    name: 'Anguilla',
  },
  {
    key: 'VI',
    name: 'U.S. Virgin Islands',
  },
  {
    key: 'IS',
    name: 'Iceland',
  },
  {
    key: 'IR',
    name: 'Iran',
  },
  {
    key: 'AM',
    name: 'Armenia',
  },
  {
    key: 'AL',
    name: 'Albania',
  },
  {
    key: 'AO',
    name: 'Angola',
  },
  {
    key: 'AQ',
    name: 'Antarctica',
  },
  {
    key: 'AS',
    name: 'American Samoa',
  },
  {
    key: 'AR',
    name: 'Argentina',
  },
  {
    key: 'AU',
    name: 'Australia',
  },
  {
    key: 'AT',
    name: 'Austria',
  },
  {
    key: 'AW',
    name: 'Aruba',
  },
  {
    key: 'IN',
    name: 'India',
  },
  {
    key: 'AX',
    name: 'Aland Islands',
  },
  {
    key: 'AZ',
    name: 'Azerbaijan',
  },
  {
    key: 'IE',
    name: 'Ireland',
  },
  {
    key: 'ID',
    name: 'Indonesia',
  },
  {
    key: 'UA',
    name: 'Ukraine',
  },
  {
    key: 'QA',
    name: 'Qatar',
  },
  {
    key: 'MZ',
    name: 'Mozambique',
  },
];

export const indiaStatesList = [
  {
    key: 'AN',
    name: 'Andaman and Nicobar Islands',
  },
  {
    key: 'AP',
    name: 'Andhra Pradesh',
  },
  {
    key: 'AR',
    name: 'Arunachal Pradesh',
  },
  {
    key: 'AS',
    name: 'Assam',
  },
  {
    key: 'BR',
    name: 'Bihar',
  },
  {
    key: 'CG',
    name: 'Chandigarh',
  },
  {
    key: 'CH',
    name: 'Chhattisgarh',
  },
  {
    key: 'DH',
    name: 'Dadra and Nagar Haveli',
  },
  {
    key: 'DD',
    name: 'Daman and Diu',
  },
  {
    key: 'DL',
    name: 'Delhi',
  },
  {
    key: 'GA',
    name: 'Goa',
  },
  {
    key: 'GJ',
    name: 'Gujarat',
  },
  {
    key: 'HR',
    name: 'Haryana',
  },
  {
    key: 'HP',
    name: 'Himachal Pradesh',
  },
  {
    key: 'JK',
    name: 'Jammu and Kashmir',
  },
  {
    key: 'JH',
    name: 'Jharkhand',
  },
  {
    key: 'KA',
    name: 'Karnataka',
  },
  {
    key: 'KL',
    name: 'Kerala',
  },
  {
    key: 'LD',
    name: 'Lakshadweep',
  },
  {
    key: 'MP',
    name: 'Madhya Pradesh',
  },
  {
    key: 'MH',
    name: 'Maharashtra',
  },
  {
    key: 'MN',
    name: 'Manipur',
  },
  {
    key: 'ML',
    name: 'Meghalaya',
  },
  {
    key: 'MZ',
    name: 'Mizoram',
  },
  {
    key: 'NL',
    name: 'Nagaland',
  },
  {
    key: 'OR',
    name: 'Odisha',
  },
  {
    key: 'PY',
    name: 'Puducherry',
  },
  {
    key: 'PB',
    name: 'Punjab',
  },
  {
    key: 'RJ',
    name: 'Rajasthan',
  },
  {
    key: 'SK',
    name: 'Sikkim',
  },
  {
    key: 'TN',
    name: 'Tamil Nadu',
  },
  {
    key: 'TS',
    name: 'Telangana',
  },
  {
    key: 'TR',
    name: 'Tripura',
  },
  {
    key: 'UP',
    name: 'Uttar Pradesh',
  },
  {
    key: 'UK',
    name: 'Uttarakhand',
  },
  {
    key: 'WB',
    name: 'West Bengal',
  },
].sort((x, y) => {
  const a = x.name.toUpperCase();
  const b = y.name.toUpperCase();
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
});

export const BillableCountries = [
  { name: 'Afghanistan', key: 'AF' },
  { name: 'Åland Islands', key: 'AX' },
  { name: 'Albania', key: 'AL' },
  { name: 'Algeria', key: 'DZ' },
  { name: 'American Samoa', key: 'AS' },
  { name: 'AndorrA', key: 'AD' },
  { name: 'Angola', key: 'AO' },
  { name: 'Anguilla', key: 'AI' },
  { name: 'Antarctica', key: 'AQ' },
  { name: 'Antigua and Barbuda', key: 'AG' },
  { name: 'Argentina', key: 'AR' },
  { name: 'Armenia', key: 'AM' },
  { name: 'Aruba', key: 'AW' },
  { name: 'Australia', key: 'AU' },
  { name: 'Austria', key: 'AT' },
  { name: 'Azerbaijan', key: 'AZ' },
  { name: 'Bahamas', key: 'BS' },
  { name: 'Bahrain', key: 'BH' },
  { name: 'Bangladesh', key: 'BD' },
  { name: 'Barbados', key: 'BB' },
  { name: 'Belarus', key: 'BY' },
  { name: 'Belgium', key: 'BE' },
  { name: 'Belize', key: 'BZ' },
  { name: 'Benin', key: 'BJ' },
  { name: 'Bermuda', key: 'BM' },
  { name: 'Bhutan', key: 'BT' },
  { name: 'Bolivia', key: 'BO' },
  { name: 'Bosnia and Herzegovina', key: 'BA' },
  { name: 'Botswana', key: 'BW' },
  { name: 'Bouvet Island', key: 'BV' },
  { name: 'Brazil', key: 'BR' },
  { name: 'British Indian Ocean Territory', key: 'IO' },
  { name: 'Brunei Darussalam', key: 'BN' },
  { name: 'Bulgaria', key: 'BG' },
  { name: 'Burkina Faso', key: 'BF' },
  { name: 'Burundi', key: 'BI' },
  { name: 'Cambodia', key: 'KH' },
  { name: 'Cameroon', key: 'CM' },
  { name: 'Canada', key: 'CA' },
  { name: 'Cape Verde', key: 'CV' },
  { name: 'Cayman Islands', key: 'KY' },
  { name: 'Central African Republic', key: 'CF' },
  { name: 'Chad', key: 'TD' },
  { name: 'Chile', key: 'CL' },
  { name: 'China', key: 'CN' },
  { name: 'Christmas Island', key: 'CX' },
  { name: 'Cocos (Keeling) Islands', key: 'CC' },
  { name: 'Colombia', key: 'CO' },
  { name: 'Comoros', key: 'KM' },
  { name: 'Congo', key: 'CG' },
  { name: 'Congo, The Democratic Republic of the', key: 'CD' },
  { name: 'Cook Islands', key: 'CK' },
  { name: 'Costa Rica', key: 'CR' },
  { name: 'Cote D\'Ivoire', key: 'CI' },
  { name: 'Croatia', key: 'HR' },
  { name: 'Cuba', key: 'CU' },
  { name: 'Cyprus', key: 'CY' },
  { name: 'Czech Republic', key: 'CZ' },
  { name: 'Denmark', key: 'DK' },
  { name: 'Djibouti', key: 'DJ' },
  { name: 'Dominica', key: 'DM' },
  { name: 'Dominican Republic', key: 'DO' },
  { name: 'Ecuador', key: 'EC' },
  { name: 'Egypt', key: 'EG' },
  { name: 'El Salvador', key: 'SV' },
  { name: 'Equatorial Guinea', key: 'GQ' },
  { name: 'Eritrea', key: 'ER' },
  { name: 'Estonia', key: 'EE' },
  { name: 'Ethiopia', key: 'ET' },
  { name: 'Falkland Islands (Malvinas)', key: 'FK' },
  { name: 'Faroe Islands', key: 'FO' },
  { name: 'Fiji', key: 'FJ' },
  { name: 'Finland', key: 'FI' },
  { name: 'France', key: 'FR' },
  { name: 'French Guiana', key: 'GF' },
  { name: 'French Polynesia', key: 'PF' },
  { name: 'French Southern Territories', key: 'TF' },
  { name: 'Gabon', key: 'GA' },
  { name: 'Gambia', key: 'GM' },
  { name: 'Georgia', key: 'GE' },
  { name: 'Germany', key: 'DE' },
  { name: 'Ghana', key: 'GH' },
  { name: 'Gibraltar', key: 'GI' },
  { name: 'Greece', key: 'GR' },
  { name: 'Greenland', key: 'GL' },
  { name: 'Grenada', key: 'GD' },
  { name: 'Guadeloupe', key: 'GP' },
  { name: 'Guam', key: 'GU' },
  { name: 'Guatemala', key: 'GT' },
  { name: 'Guernsey', key: 'GG' },
  { name: 'Guinea', key: 'GN' },
  { name: 'Guinea-Bissau', key: 'GW' },
  { name: 'Guyana', key: 'GY' },
  { name: 'Haiti', key: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', key: 'HM' },
  { name: 'Holy See (Vatican City State)', key: 'VA' },
  { name: 'Honduras', key: 'HN' },
  { name: 'Hong Kong', key: 'HK' },
  { name: 'Hungary', key: 'HU' },
  { name: 'Iceland', key: 'IS' },
  { name: 'India', key: 'IN' },
  { name: 'Indonesia', key: 'ID' },
  { name: 'Iran, Islamic Republic Of', key: 'IR' },
  { name: 'Iraq', key: 'IQ' },
  { name: 'Ireland', key: 'IE' },
  { name: 'Isle of Man', key: 'IM' },
  { name: 'Israel', key: 'IL' },
  { name: 'Italy', key: 'IT' },
  { name: 'Jamaica', key: 'JM' },
  { name: 'Japan', key: 'JP' },
  { name: 'Jersey', key: 'JE' },
  { name: 'Jordan', key: 'JO' },
  { name: 'Kazakhstan', key: 'KZ' },
  { name: 'Kenya', key: 'KE' },
  { name: 'Kiribati', key: 'KI' },
  { name: 'Korea, Democratic People\'S Republic of', key: 'KP' },
  { name: 'Korea, Republic of', key: 'KR' },
  { name: 'Kuwait', key: 'KW' },
  { name: 'Kyrgyzstan', key: 'KG' },
  { name: 'Lao People\'S Democratic Republic', key: 'LA' },
  { name: 'Latvia', key: 'LV' },
  { name: 'Lebanon', key: 'LB' },
  { name: 'Lesotho', key: 'LS' },
  { name: 'Liberia', key: 'LR' },
  { name: 'Libyan Arab Jamahiriya', key: 'LY' },
  { name: 'Liechtenstein', key: 'LI' },
  { name: 'Lithuania', key: 'LT' },
  { name: 'Luxembourg', key: 'LU' },
  { name: 'Macao', key: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', key: 'MK' },
  { name: 'Madagascar', key: 'MG' },
  { name: 'Malawi', key: 'MW' },
  { name: 'Malaysia', key: 'MY' },
  { name: 'Maldives', key: 'MV' },
  { name: 'Mali', key: 'ML' },
  { name: 'Malta', key: 'MT' },
  { name: 'Marshall Islands', key: 'MH' },
  { name: 'Martinique', key: 'MQ' },
  { name: 'Mauritania', key: 'MR' },
  { name: 'Mauritius', key: 'MU' },
  { name: 'Mayotte', key: 'YT' },
  { name: 'Mexico', key: 'MX' },
  { name: 'Micronesia, Federated States of', key: 'FM' },
  { name: 'Moldova, Republic of', key: 'MD' },
  { name: 'Monaco', key: 'MC' },
  { name: 'Mongolia', key: 'MN' },
  { name: 'Montserrat', key: 'MS' },
  { name: 'Morocco', key: 'MA' },
  { name: 'Mozambique', key: 'MZ' },
  { name: 'Myanmar', key: 'MM' },
  { name: 'Namibia', key: 'NA' },
  { name: 'Nauru', key: 'NR' },
  { name: 'Nepal', key: 'NP' },
  { name: 'Netherlands', key: 'NL' },
  { name: 'Netherlands Antilles', key: 'AN' },
  { name: 'New Caledonia', key: 'NC' },
  { name: 'New Zealand', key: 'NZ' },
  { name: 'Nicaragua', key: 'NI' },
  { name: 'Niger', key: 'NE' },
  { name: 'Nigeria', key: 'NG' },
  { name: 'Niue', key: 'NU' },
  { name: 'Norfolk Island', key: 'NF' },
  { name: 'Northern Mariana Islands', key: 'MP' },
  { name: 'Norway', key: 'NO' },
  { name: 'Oman', key: 'OM' },
  { name: 'Pakistan', key: 'PK' },
  { name: 'Palau', key: 'PW' },
  { name: 'Palestinian Territory, Occupied', key: 'PS' },
  { name: 'Panama', key: 'PA' },
  { name: 'Papua New Guinea', key: 'PG' },
  { name: 'Paraguay', key: 'PY' },
  { name: 'Peru', key: 'PE' },
  { name: 'Philippines', key: 'PH' },
  { name: 'Pitcairn', key: 'PN' },
  { name: 'Poland', key: 'PL' },
  { name: 'Portugal', key: 'PT' },
  { name: 'Puerto Rico', key: 'PR' },
  { name: 'Qatar', key: 'QA' },
  { name: 'Reunion', key: 'RE' },
  { name: 'Romania', key: 'RO' },
  { name: 'Russian Federation', key: 'RU' },
  { name: 'RWANDA', key: 'RW' },
  { name: 'Saint Helena', key: 'SH' },
  { name: 'Saint Kitts and Nevis', key: 'KN' },
  { name: 'Saint Lucia', key: 'LC' },
  { name: 'Saint Pierre and Miquelon', key: 'PM' },
  { name: 'Saint Vincent and the Grenadines', key: 'VC' },
  { name: 'Samoa', key: 'WS' },
  { name: 'San Marino', key: 'SM' },
  { name: 'Sao Tome and Principe', key: 'ST' },
  { name: 'Saudi Arabia', key: 'SA' },
  { name: 'Senegal', key: 'SN' },
  { name: 'Serbia and Montenegro', key: 'CS' },
  { name: 'Seychelles', key: 'SC' },
  { name: 'Sierra Leone', key: 'SL' },
  { name: 'Singapore', key: 'SG' },
  { name: 'Slovakia', key: 'SK' },
  { name: 'Slovenia', key: 'SI' },
  { name: 'Solomon Islands', key: 'SB' },
  { name: 'Somalia', key: 'SO' },
  { name: 'South Africa', key: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', key: 'GS' },
  { name: 'Spain', key: 'ES' },
  { name: 'Sri Lanka', key: 'LK' },
  { name: 'Sudan', key: 'SD' },
  { name: 'Suriname', key: 'SR' },
  { name: 'Svalbard and Jan Mayen', key: 'SJ' },
  { name: 'Swaziland', key: 'SZ' },
  { name: 'Sweden', key: 'SE' },
  { name: 'Switzerland', key: 'CH' },
  { name: 'Syrian Arab Republic', key: 'SY' },
  { name: 'Taiwan, Province of China', key: 'TW' },
  { name: 'Tajikistan', key: 'TJ' },
  { name: 'Tanzania, United Republic of', key: 'TZ' },
  { name: 'Thailand', key: 'TH' },
  { name: 'Timor-Leste', key: 'TL' },
  { name: 'Togo', key: 'TG' },
  { name: 'Tokelau', key: 'TK' },
  { name: 'Tonga', key: 'TO' },
  { name: 'Trinidad and Tobago', key: 'TT' },
  { name: 'Tunisia', key: 'TN' },
  { name: 'Turkey', key: 'TR' },
  { name: 'Turkmenistan', key: 'TM' },
  { name: 'Turks and Caicos Islands', key: 'TC' },
  { name: 'Tuvalu', key: 'TV' },
  { name: 'Uganda', key: 'UG' },
  { name: 'Ukraine', key: 'UA' },
  { name: 'United Arab Emirates', key: 'AE' },
  { name: 'United Kingdom', key: 'GB' },
  { name: 'United States', key: 'US' },
  { name: 'United States Minor Outlying Islands', key: 'UM' },
  { name: 'Uruguay', key: 'UY' },
  { name: 'Uzbekistan', key: 'UZ' },
  { name: 'Vanuatu', key: 'VU' },
  { name: 'Venezuela', key: 'VE' },
  { name: 'Viet Nam', key: 'VN' },
  { name: 'Virgin Islands, British', key: 'VG' },
  { name: 'Virgin Islands, U.S.', key: 'VI' },
  { name: 'Wallis and Futuna', key: 'WF' },
  { name: 'Western Sahara', key: 'EH' },
  { name: 'Yemen', key: 'YE' },
  { name: 'Zambia', key: 'ZM' },
  { name: 'Zimbabwe', key: 'ZW' },
].sort((x, y) => {
  const a = x.name.toUpperCase();
  const b = y.name.toUpperCase();
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
});

// Based on https://cloudfour.com/thinks/autofill-what-web-devs-should-know-but-dont/
export const AutocompleteNames = {
  email: 'home email',
  address: 'shipping street-address',
  city: 'shipping address-level2',
  state: 'shipping address-level1',
  country: 'shipping country-name',
  'postal-code': 'shipping postal-code',
};

export class FormField {
  constructor({
    initValue, errorMessage, minValue, maxValue, regex,
    minLength, required, name, key, type, placeholder,
    formName, autocompleteName, disabled, validateOnInput,
  }) {
    this.initValue = initValue || null;
    this.minValue = minValue || null;
    this.maxValue = maxValue || null;
    this.regex = regex || null;
    this.touched = false;
    this.model = initValue || null;
    this.minLength = minLength || 1;
    this.required = (required === undefined) ? true : required;
    this.name = name || null;
    this.key = key || null;
    this.type = type || 'text';
    this.placeholder = placeholder || '';
    this.formName = formName || '';
    this.autocompleteName = autocompleteName || (AutocompleteNames[this.formName] || '');
    this.disabled = (disabled === undefined) ? false : disabled;
    this.validateOnInput = (validateOnInput === undefined) ? false : validateOnInput;

    let defaultMessage = 'Invalid input';
    if (this.name) {
      defaultMessage = `Enter a valid ${this.name}`;
    }
    this.errorMessage = errorMessage || defaultMessage;

    this.customErrorMessage = null;
  }

  get isValid() {
    if (this.customErrorMessage) {
      return ['custom', false];
    }

    if (this.required && this.model === '') {
      return ['required', false];
    }

    if (this.minValue !== null) {
      return ['minValue', this.model > this.minValue];
    }

    if (this.maxValue !== null) {
      return ['maxValue', this.model <= this.maxValue];
    }

    if (this.regex) {
      return ['regex', !this.regex.text(this.model)];
    }

    if (this.required && this.model.length < this.minLength) {
      return ['minLength', false];
    }

    return [null, true];
  }

  get showError() {
    if (this.customErrorMessage) {
      return true;
    }
    return (this.touched && !this.isValid[1]);
  }

  get message() {
    if (this.customErrorMessage) {
      return this.customErrorMessage;
    }

    if (this.showError) {
      return this.errorMessage;
    }
    return null;
  }

  markTouched() {
    this.touched = true;
  }
}
export class FormHandler {
  constructor({ fields, fieldsConfig }) {
    if (fieldsConfig) {
      const newFields = fieldsConfig.map((f) => new FormField(f));
      this.fields = newFields;
    } else {
      this.fields = fields;
    }

    this.f = {};
    this.fields.forEach((field) => {
      if (field.key) {
        this.f[field.key] = field;
      }
    });
  }

  get isValid() {
    const errors = this.validate();
    return errors.length === 0;
  }

  updateInitValue(obj) {
    this.fields.forEach((field) => {
      if (obj[field.key] !== undefined) {
        let value = obj[field.key];
        if (value === null) {
          value = '';
        }
        field.initValue = value;
        field.model = value;
      }
    });
  }

  formData() {
    const data = {};
    this.fields.forEach((field) => {
      data[field.key] = field.model;
    });
    return data;
  }

  validate() {
    this.fields.map((field) => field.markTouched());
    const errors = this.fields.map((field) => field.message).filter((f) => f !== null);
    return errors;
  }

  static fromObject(obj) {
    const fields = [];
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      fields.push(new FormField({
        initValue: val,
        key,
      }));
    });
    return new FormHandler(fields);
  }
}
