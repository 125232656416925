<template>
  <div class="sign-up forgot-password">
    <div class="flex">
      <div class="flex-col-right">
        <div class="intro-block" v-if="showIntro">
          <h1 class="signup-text title">Don’t worry! We’ll help you set a new password.</h1>
          <div :class="['form-group', (formFields.email.showError ? 'error-field' : '')]">
            <label class="field-label" for="workemail">Enter your email</label>
            <input
              :class="[!formFields.email.showError ? 'tick-icon' : null]"
              type="email"
              v-model="userInfo.email"
              name="workemail"
              id="workemail"
              placeholder=""
              autocomplete="off"
              autofocus
              @keyup.enter="submitEmail"
            />
            <p v-if="formFields.email.showError" class="error-msg">{{formFields.email.errorMsg}}</p>
          </div>
          <div v-if="formFields.noUser.errorMsg"
            :class="['form-group', (formFields.noUser.showError ? 'error-field' : '')]">
            <p v-if="formFields.noUser.showError" class="error-msg">{{formFields.noUser.errorMsg}}</p>
          </div>
          <button  type="submit" :disabled="showLoader && (userInfo.email === null || userInfo.email === '')"
            class="console-btn" @click="submitEmail">Continue
          </button>
        </div>
        <div v-if="showPasswordBlock" class="create-password-block">
          <h1 class="signup-text title">Reset your password</h1>
          <div :class="['form-group new-password-div', (formFields.newPassword.showError ? 'error-field' : '')]">
            <label class="field-label password" for="password">Enter your new password</label>
            <input
              :class="[!formFields.newPassword.showError ? 'tick-icon' : null]"
              :type="[showPassCode ? 'text' : 'password']"
              v-model="userInfo.newPassword"
              name="password"
              id="password"
              class="password-box"
              placeholder=" "
              autocomplete="on"
              autofocus
              @keyup.enter="submitPassword()"
            />
            <p v-if="formFields.newPassword.showError" class="error-msg">{{formFields.newPassword.errorMsg}}</p>
          </div>
          <div :class="['form-group confirm-password-div',
          (formFields.confirmPassword.showError ? 'error-field' : '')]">
            <label class="field-label password" for="password">Confirm password</label>
            <input
              :class="[!formFields.confirmPassword.showError ? 'tick-icon' : null]"
              :type="[showPassCode ? 'text' : 'password']"
              v-model="userInfo.confirmPassword"
              name="password"
              id="password"
              class="password-box"
              placeholder=" "
              autocomplete="on"
              @keyup.enter="submitPassword()"
            />
            <label class="check-box-group">
              <input v-model="checked" class="check-box" type="checkbox"/>
              <span @click="showPassCode = !showPassCode" class="tickmark"></span>
            </label>
            <span @click="showPassword()" class="checkbox-content">Show Password</span>
            <p v-if="formFields.confirmPassword.showError" class="error-msg">{{formFields.confirmPassword.errorMsg}}</p>
          </div>
          <p v-if="validatePassword" class="error-msg">
            Passwords do not match.
          </p>
          <div v-if="formFields.response.errorMsg"
            :class="['form-group', (formFields.response.showError ? 'error-field' : '')]">
            <p v-if="formFields.response.showError" class="error-msg">{{formFields.response.errorMsg}}</p>
          </div>
          <button :disabled="showLoader && (userInfo.confirmPassword === null || userInfo.confirmPassword === ''
           || userInfo.newPassword === null || userInfo.newPassword === ''
          )"
            class="console-btn" type="submit" @click="submitPassword()">Continue
          </button>
        </div>
        <div v-if="emailSentSuccess" class="success-field email">
          <svg><use v-bind="{'xlink:href':'#success-illustration'}"></use></svg>
          <p>Check your email for instructions to reset your password.</p>
        </div>
        <div v-if="showSuccessBlock" class="success-field">
          <svg><use v-bind="{'xlink:href':'#success-illustration'}"></use></svg>
          <h1>Success</h1>
          <p>Your password has been reset successfully!</p>
          <div v-if="formFields.response.errorMsg"
            :class="['form-group', (formFields.response.showError ? 'error-field' : '')]">
            <p v-if="formFields.response.showError" class="error-msg">{{formFields.response.errorMsg}}</p>
          </div>
          <router-link class="console-btn"
            :to="{ name: 'login', query: { next: '/user/project/create/' } }">
              Log In
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.forgot-password {
  .flex-col-right {
    margin-left: 0;
    margin-top: size(100);
    max-width: size(500);
    .title {
      margin-bottom: size(30);
    }
    .button {
      margin-top: size(14);
    }
    .create-password-block {
      & >.error-msg {
        color: var(--onboard-error-color);
        padding: 0 0 size(10);
      }
      .checkbox-content {
        display: inline-block;
        vertical-align: middle;
        margin-top: size(20);
        padding-left: size(10);
        font-size: size(16);
        line-height: size(19);
        color: var(--ca-title-color);
      }
    }
    .success-field {
      text-align: center;
      &.email {
        p {
          padding-top: size(20);
          padding-bottom: 0;
        }
      }
      h1 {
        padding: size(10) 0;
        font-weight: bold;
        font-size: size(42);
        line-height: size(56);
        color: var(--ca-title-color);
        font-family: var(--hero-font);
      }
      p {
        padding-bottom: size(30);
        font-weight: 600;
        font-size: size(16);
        line-height: size(19);
        color: var(--ca-title-color);
      }
    }
  }
}
</style>

<script>
import { mapState } from 'vuex';

import { FieldHandler } from '@/core/utils/formModels';
import { User } from '@/store/modules/user';
import '@/assets/styles/views/form.scss';

export default {

  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      page: (state) => state.page.activePage,
    }),
  },

  mounted() {
    if (this.$route.query && this.$route.query.next_url === 'forgot-password' && this.$route.query.code) {
      this.showIntro = false;
      this.showPasswordBlock = true;
    }
    if (this.$route.query && !this.$route.query.code) {
      this.showIntro = true;
      this.showPasswordBlock = false;
    }
  },

  data() {
    return {
      userInfo: {
        name: null,
        newPassword: null,
        confirmPassword: null,
      },
      checked: false,
      showIntro: true,
      showPassCode: false,
      emailSentSuccess: false,
      showPasswordBlock: false,
      showSuccessBlock: false,
      signUpInitiated: false,
      showLoader: false,
      validatePassword: false,
      formFields: {
        email: new FieldHandler({
          errorMsg: 'Please enter a valid email.',
          // eslint-disable-next-line max-len
          regExp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        }),
        newPassword: new FieldHandler({
          errorMsg: 'Password must be least 8 characters long with a mix of letters, numbers and symbols.',
          minValue: 8,
        }),
        confirmPassword: new FieldHandler({
          errorMsg: 'Password must be least 8 characters long with a mix of letters, numbers and symbols.',
          minValue: 8,
        }),
        response: new FieldHandler({
          errorMsg: 'Something went wrong!',
        }),
        noUser: new FieldHandler({
          errorMsg: 'User not found.',
        }),
      },
    };
  },
  methods: {
    validateEmail() {
      let count = 0;
      if (!this.formFields.email.validateField(this.userInfo.email)) {
        count += 1;
      }
      return (count === 0);
    },
    showPassword() {
      this.checked = !this.checked;
      this.showPassCode = !this.showPassCode;
    },
    validateNewPassword() {
      let count = 0;
      if (!this.formFields.newPassword.validateField(this.userInfo.newPassword)) {
        count += 1;
      }

      if (!this.formFields.confirmPassword.validateField(this.userInfo.confirmPassword)) {
        count += 1;
      }
      return (count === 0);
    },
    submitPassword() {
      this.showLoader = true;
      if ((this.userInfo.newPassword !== null) && (this.userInfo.newpassword !== '')
        && (this.userInfo.confirmPassword !== null) && (this.userInfo.confirmPassword !== '')
        && (this.userInfo.confirmPassword !== this.userInfo.newPassword)
      ) {
        this.validatePassword = true;
      } else {
        this.validatePassword = false;
        if (this.validateNewPassword()) {
          const password = this.userInfo.confirmPassword;
          const data = this.$route.query.code;
          User.resetPassword(this.$apiInstance, data, password)
            .then((resp) => {
              if (resp) {
                if (resp.data.good) {
                  this.showLoader = false;
                  this.showPasswordBlock = false;
                  this.showSuccessBlock = true;
                }
                if (resp.data.error) {
                  this.formFields.response.showError = true;
                  console.log(resp.error);
                  this.formFields.response.errorMsg = resp.data.error.message;
                }
              }
            })
            .catch((err) => {
              this.formFields.response.showError = true;
              this.formFields.response.errorMsg = 'Something went wrong.';
              this.showLoader = false;
              console.error('api', err);
            });
        }
      }
    },
    submitEmail() {
      this.showLoader = true;
      if (this.validateEmail()) {
        const data = {};
        data.email = this.userInfo.email;
        const url = window.location.href;
        data.new_password_callback_url = `${url}/?next_url=forgot-password`;
        User.forgotPassword(this.$apiInstance, data)
          .then((resp) => {
            if (resp) {
              this.showLoader = false;
              if (resp.data.good) {
                this.emailSentSuccess = true;
                this.showIntro = false;
              }
              if (resp.data.error) {
                this.formFields.noUser.showError = true;
                console.log(resp.error);
                this.formFields.noUser.errorMsg = resp.data.error.message;
              }
            } else {
              this.formFields.noUser.showError = true;
              this.formFields.noUser.errorMsg = 'No account found for this email.';
              this.showLoader = false;
            }
          })
          .catch((err) => {
            this.formFields.response.showError = true;
            this.formFields.response.errorMsg = 'User Not found!';
            this.showLoader = false;
            console.error('api', err);
          });
      }
    },
  },
};
</script>
