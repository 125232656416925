<template>
  <div class="sign-up login" v-if="dataLoaded">
    <div class="flex" v-on:keyup.enter="login">
      <div class="flex-col-left hero-banner">
        <header class="signup-header">
          <a :href="homeUrl" class="rz-logo">
            <img class="logo" src="@/assets/rca-logo-white.svg" alt="Cricket API Logo" />
          </a>
          <div
            class="user-info"
            v-if="($route.name !== 'signUp') && ($route.name !== 'login')"
          >
          <template v-if="activeUser && activeUser.name">
            <svg class="profile-icon">
              <use v-bind="{'xlink:href':'#profile-icon'}" />
            </svg>
            <span class="user-name">{{ activeUser.name }}</span>
            <UserDropdown />
          </template>
          </div>
        </header>
        <h1 class="hero-banner--title" v-if="page.title">{{ page.title }}</h1>
        <div>
          <template v-for="section in page.sections[1].detail">
            <template>
              <div v-for="info in section" :key="info.key">
                <svg>
                  <use v-bind="{'xlink:href':'#check-icon'}" />
                </svg>
                <div class="content-wrapper">
                  <h2>{{info.name}}</h2>
                  <p>{{info.desc}}</p>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
      <div class="flex-col-right" :class="[signUpInitiated ? 'initiated' : null]">
        <div class="form-wrapper">
          <h1 class="signup-text">Sign in  to your account</h1>
          <p class="login-info">
            Don't have an account?
            <router-link class="login-info-link"
              :to="{ name: 'signUp', query: loginParams }">
                Sign up
            </router-link>
          </p>
          <div>
            <div :class="['form-group', (formFields.email.showError ? 'error-field' : '')]">
              <label class="field-label" for="workemail">Email</label>
              <input
                :class="[!formFields.email.showError ? 'tick-icon' : null]"
                type="email"
                v-model="userInfo.email"
                name="workemail"
                id="workemail"
                placeholder=""
                autocomplete="off"
                autofocus
              />
              <p v-if="formFields.email.showError" class="error-msg">{{formFields.email.errorMsg}}</p>
            </div>
            <div :class="['form-group password-div', (formFields.password.showError ? 'error-field' : '')]">
              <label class="field-label password" for="password">Password</label>
              <router-link class="forget-pass-btn"
                :to="{ name: 'forgotPassword'}">
                  Forgot password?
              </router-link>
              <input
                :class="[!formFields.password.showError ? 'tick-icon' : null]"
                :type="[showPassCode ? 'text' : 'password']"
                v-model="userInfo.password"
                name="password"
                id="password"
                placeholder=" "
                autocomplete="on"
              />
              <p v-if="formFields.password.showError" class="error-msg">{{formFields.password.errorMsg}}</p>
            </div>
            <div :class="['form-group', (formFields.response.showError ? 'error-field' : '')]">
              <p v-if="formFields.response.showError" class="error-msg">{{formFields.response.errorMsg}}</p>
            </div>
            <button :disabled="showLoader  && validateSubmit"
            :class="[validateSubmit ? 'disabled-btn': '']"
            class="console-btn" type="submit" @click="login">
              <div v-if="!loadingState.status.isLoaded">
                <rz-request-state :state="loadingState" />
              </div>
             <span v-if="showLogin">Sign in</span>
            </button>
          </div>
        </div>
        <footer class="footer">
          <div class="footer-flex-col-left">
            <p>© Roanuz Softwares Private Limited</p>
          </div>
          <div class="footer-flex-col-right"
            v-if="tocLinks.linkslist">
            <template v-for="(linkItem, index) in tocLinks.linkslist.links">
              <rz-link-item :key="index" :link="linkItem" />
            </template>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.image-area {
  .show-container {
    img {
      max-height: size(435);
    }
  }
}
.sign-up {
  .flex-col-right {
    .form-wrapper {
      padding-bottom: size(100);
      @media screen and (min-width: $breakpoint-md) {
        padding-bottom: size(200);
      }
    }
  }
}
</style>

<script>
import { mapState } from 'vuex';
import Config from '@/config';
import { allMetaMixins } from '@/mixin/meta';

import { RequestState, RequestStateStatus } from '@/api/core';
import { FieldHandler } from '@/core/utils/formModels';
import { User } from '@/store/modules/user';
import '@/assets/styles/views/form.scss';

export default {
  mixins: allMetaMixins(),
  title: 'Sign in to your Roanuz account | Roanuz Cricket API',
  computed: {
    ...mapState({
      dataLoaded: (state) => state.page.activePageState.status.isLoaded,
      page: (state) => state.page.activePage,
      tocLinks: (state) => state.link.cricketFooterTOC,
    }),
    validateSubmit() {
      if ((this.userInfo.email === null) || (this.userInfo.password === null)
        || (this.userInfo.email === '') || (this.userInfo.password === '')
      ) {
        return true;
      }
      return false;
    },
    loginParams() {
      if (this.$route && this.$route.query && this.$route.query.next_url) {
        return { next_url: this.$route.query.next_url };
      }
      if (this.$route && this.$route.query && this.$route.query.next) {
        return { next: this.$route.query.next };
      }
      return { next: '/user/project/create/' };
    },
  },

  watch: {
    $route(to, from) {
      if (
        to.path !== from.path
        || to.params.sport !== from.params.sport
        || to.params.page !== from.params.page
      ) {
        console.log('Refreshing page because route change');
        this.refreshPage();
      }
    },
  },

  serverPrefetch() {
    return this.fetchData();
  },

  mounted() {
    if (!this.dataLoaded) {
      this.fetchData();
    }
    if (this.dataLoaded) {
      const sameRoute = this.page.route_value.includes(this.$route.meta.routeValue);
      if (!sameRoute) {
        console.log('Refreshing page because page mounted with different route');
        this.refreshPage();
      }
    }
  },

  data() {
    return {
      loadingState: new RequestState(),
      userInfo: {
        email: null,
        password: null,
      },
      signUpInitiated: false,
      showLoader: false,
      showPassCode: false,
      showLogin: true,
      checked: false,
      homeUrl: Config.cricketHost,
      formFields: {
        email: new FieldHandler({
          errorMsg: 'No account exists for this email.',
          // eslint-disable-next-line max-len
          regExp: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        }),
        password: new FieldHandler({
          errorMsg: 'The password you have entered is incorrect.',
          minValue: 8,
        }),
        response: new FieldHandler({
          errorMsg: 'Something went wrong!',
        }),
      },
      linkItemList: [
        {
          text: 'API terms of use.',
          url: `${Config.cricketHost}/legal/API-Terms-of-use/`,
        },
        {
          text: 'Terms & Privacy policy.',
          url: `${Config.cricketHost}/legal/Privacy-Policy/`,
        },
        {
          text: 'Refund policy',
          url: `${Config.cricketHost}/legal/Refund-Policy/`,
        },
      ],
    };
  },
  methods: {
    fetchData() {
      const route = '/user/pages/';
      const routeValue = 'signup';
      return this.$store
        .dispatch('page/fetchActivePage', { route, routeValue })
        .catch((err) => {
          console.error('Error on fetch Active Page', err);
        });
    },

    validateInput() {
      let count = 0;
      if (this.userInfo.email === '' || this.userInfo.email === null) {
        this.formFields.email.errorMsg = 'Please enter a valid email.';
      }
      if (this.userInfo.password === '' || this.userInfo.password === null) {
        this.formFields.password.errorMsg = 'Please enter your Password.';
      }
      if (!this.formFields.email.validateField(this.userInfo.email)) {
        count += 1;
      }

      if (!this.formFields.password.validateField(this.userInfo.password)) {
        count += 1;
      }
      return (count === 0);
    },

    processNext(user) {
      const successUrl = decodeURI(this.$route.query.next_url);
      this.$store.commit('boot/updateUser', { user });
      if (this.$route.query.next_url) {
        console.log(`Login Completed ${user.name}, Moving to ${successUrl}`);
        window.location.href = successUrl;
      } else {
        const successRoute = decodeURI(this.$route.query.next || '/user/project/create/');
        console.log(`Login Completed ${user.name}, Moving to ${successRoute}`);
        this.$router.push(successRoute);
      }
    },

    login() {
      this.showLoader = true;
      this.showLogin = false;
      if (this.validateInput()) {
        this.loadingState.status = RequestStateStatus.Loading;
        User.login(this.$apiInstance, this.userInfo)
          .then(({ user, error }) => {
            this.loadingState.status = RequestStateStatus.Loaded;
            this.showLogin = true;
            this.showLoader = false;
            if (error) {
              console.log('Invalid Login Request', error);
              this.formFields.response.showError = true;
              if (error.message === 'User not found') {
                this.formFields.response.errorMsg = 'No account exists for this email.';
              } else if (error.message === 'Wrong password') {
                this.formFields.response.errorMsg = 'The password you have entered is incorrect.';
              } else {
                this.formFields.response.errorMsg = error.message;
              }
            } else {
              this.processNext(user);
            }
          })
          .catch((err) => {
            this.formFields.response.showError = true;
            this.formFields.response.errorMsg = 'Internal Error.';
            this.showLoader = false;

            this.loadingState.status = RequestStateStatus.Error;
            this.loadingState.ref = err;
            console.error('Unhandled error during Login', err);
          });
      } else {
        this.showLogin = true;
      }
    },
    refreshPage() {
      this.$store.commit('page/resetActivePage');
      this.fetchData();
    },
  },
};
</script>
